import { Response } from "@iq/medusa-client"
import {
  AdminKitListsItemRes,
  AdminKitListsListRes,
} from "@iq/medusa-client/dist/resources/admin/mp/kit-lists"
import { useQuery } from "react-query"
import { useMedusa } from "../../../contexts"
import { UseQueryOptionsWrapper } from "../../../types"
import { adminKitListKeys } from "./mutations"

type KitListQueryKeys = typeof adminKitListKeys

export const useAdminKitList = (
  id: string,
  options?: UseQueryOptionsWrapper<
    Response<any>,
    Error,
    ReturnType<KitListQueryKeys["detail"]>
  >
) => {
  const { client } = useMedusa()
  const { data, ...rest } = useQuery(
    adminKitListKeys.detail(id),
    () => client.admin.kitLists.retrieve(id),
    options
  )
  return { ...data, ...rest } as const
}

export const useAdminKitLists = (
  query?: any,
  options?: UseQueryOptionsWrapper<
    Response<AdminKitListsListRes>,
    Error,
    ReturnType<KitListQueryKeys["list"]>
  >
) => {
  const { client } = useMedusa()
  const { data, ...rest } = useQuery(
    adminKitListKeys.list(query),
    () => client.admin.kitLists.list(query),
    options
  )
  return { ...data, ...rest } as const
}

export const useAdminKitListItem = (
  id: string,
  itemId: string,
  options?: UseQueryOptionsWrapper<
    Response<AdminKitListsItemRes>,
    Error,
    ReturnType<any>
  >
) => {
  const { client } = useMedusa()
  const { data, ...rest } = useQuery(
    adminKitListKeys.detail(id),
    () => client.admin.kitLists.getItem(id, itemId),
    options
  )
  return { ...data, ...rest } as const
}
