import { useMedusa } from "../../../contexts"
import { useMutation, UseMutationOptions, useQueryClient } from "react-query"
import { queryKeysFactory } from "../../utils"
import { Response } from "@iq/medusa-client"
import {
  AdminKitListRes,
  AdminPostKitListReq,
} from "@iq/medusa-client/dist/resources/admin/mp/kit-lists"
import { buildOptions } from "../../utils/buildOptions"

const ADMIN_KIT_LISTS_QUERY_KEY = `admin-kit-lists` as const

export const adminKitListKeys = queryKeysFactory(ADMIN_KIT_LISTS_QUERY_KEY)

export const useAdminUpdateKitList = (
  id: string,
  options?: UseMutationOptions<
    Response<AdminKitListRes>,
    Error,
    AdminPostKitListReq
  >
) => {
  const { client } = useMedusa()
  const queryClient = useQueryClient()
  return useMutation(
    (payload: AdminPostKitListReq) => client.admin.kitLists.update(id, payload),
    buildOptions(
      queryClient,
      [adminKitListKeys.lists(), adminKitListKeys.detail(id)],
      options
    )
  )
}

export const useAdminUpdateKitListItem = (
  id: string,

  options?: UseMutationOptions<Response<any>, Error, any>
) => {
  const { client } = useMedusa()
  const queryClient = useQueryClient()
  return useMutation(
    ({
      itemId,
      variant_ids,
      quantity,
      name,
    }: {
      itemId: string
      variant_ids: string[]
      quantity: number
      name: string
    }) =>
      client.admin.kitLists.updateItem(id, itemId, {
        variant_ids,
        quantity,
        name,
      }),
    buildOptions(
      queryClient,
      [adminKitListKeys.lists(), adminKitListKeys.detail(id)],
      options
    )
  )
}

export const useAdminAddKitListItem = (
  id: string,
  options?: UseMutationOptions<Response<any>, Error, any>
) => {
  const { client } = useMedusa()
  const queryClient = useQueryClient()
  return useMutation(
    (payload: any) => client.admin.kitLists.addItem(id, payload),
    buildOptions(
      queryClient,
      [adminKitListKeys.lists(), adminKitListKeys.detail(id)],
      options
    )
  )
}

export const useAdminRemoveKitListItem = (
  id: string,
  options?: UseMutationOptions<Response<any>, Error, any>
) => {
  const { client } = useMedusa()
  const queryClient = useQueryClient()
  return useMutation(
    (itemId: string) => client.admin.kitLists.removeItem(id, itemId),
    buildOptions(
      queryClient,
      [adminKitListKeys.lists(), adminKitListKeys.detail(id)],
      options
    )
  )
}

export const useAdminDeleteKitList = (
  id: string,
  options?: UseMutationOptions<Response<any>, Error, any>
) => {
  const { client } = useMedusa()
  const queryClient = useQueryClient()
  return useMutation(
    () => client.admin.kitLists.delete(id),
    buildOptions(
      queryClient,
      [adminKitListKeys.lists(), adminKitListKeys.detail(id)],
      options
    )
  )
}

export const useAdminCreateKitList = (
  options?: UseMutationOptions<Response<AdminKitListRes>, Error, any>
) => {
  const { client } = useMedusa()
  const queryClient = useQueryClient()
  return useMutation(
    (payload: any) => client.admin.kitLists.create(payload),
    buildOptions(queryClient, adminKitListKeys.lists(), options)
  )
}
